'use client';

import { useSearchParams } from 'next/navigation';
import Button from '@/components/atoms/Button';
import SurveyQuestion from '@/components/molecules/SurveyQuestion';
import NotFound from '@/app/[lang]/not-found';
import { RATING_OPTIONS } from './consts';
import { useForm } from './hooks';
const SurveyForm = () => {
  const searchParams = useSearchParams();
  const transactionId = searchParams.get('tx');
  const {
    handleSubmit,
    onChange,
    selectedOption,
    isLoading,
    error
  } = useForm(transactionId);
  if (!transactionId) {
    return NotFound();
  }
  return <form className="mt-16 px-5" onSubmit={handleSubmit} data-sentry-component="SurveyForm" data-sentry-source-file="index.tsx">
      <div className="flex w-full flex-col items-center">
        <SurveyQuestion<number> name="rating" label="How satisfied are you with our LocalCoin ATM services?" options={RATING_OPTIONS} {...{
        selectedOption,
        onChange
      }} data-sentry-element="SurveyQuestion" data-sentry-source-file="index.tsx" />
        <Button type="submit" className="mt-5 w-full lg:max-w-40" disabled={isLoading} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          Submit
        </Button>
      </div>
      {error && <p className="text-center text-error-300">
          An error has occurred when submitting the survey
        </p>}
    </form>;
};
export default SurveyForm;