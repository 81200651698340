import { SurveyOption } from '@/components/molecules/SurveyQuestion/types';

export const SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM = 'tx';
export const SURVEY_PAGE_RATING_QUERY_PARAM = 'rating';
export const SURVEY_PAGE_LOCATION_QUERY_PARAM = 'country_code';
export const SURVEY_PAGE_DIRECT_FEEDBACK_QUERY_PARAM = 'comment';

export const RATING_OPTIONS: SurveyOption<number>[] = Array.from({ length: 5 }, (_, index) => {
  const value = index + 1;

  return {
    label: value.toString(),
    value,
  };
});
