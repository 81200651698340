import { ROUTES } from '@/routes';

import {
  SURVEY_PAGE_LOCATION_QUERY_PARAM,
  SURVEY_PAGE_RATING_QUERY_PARAM,
  SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM,
} from './consts';

export const getSubmitSurveyUrl = (
  transactionId: string | null,
  rating?: number | null,
  countryCode?: string,
): string => {
  if (!transactionId || typeof rating !== 'number') {
    return ROUTES.APISurveySubmit;
  }

  const searchParams = new URLSearchParams();

  searchParams.append(SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM, transactionId);
  searchParams.append(SURVEY_PAGE_RATING_QUERY_PARAM, rating.toString());

  if (countryCode) {
    searchParams.append(SURVEY_PAGE_LOCATION_QUERY_PARAM, countryCode);
  }

  return `${ROUTES.APISurveySubmit}?${searchParams.toString()}`;
};
