import { useRouter } from 'next/navigation';
import { FormEventHandler, useCallback, useState } from 'react';
import useSWRMutation from 'swr/mutation';

import { SurveyOption } from '@/components/molecules/SurveyQuestion/types';
import { ROUTES } from '@/routes';

import { addLocale } from '@/utils/locales';
import { useLang } from '@/hooks/useLang';
import { makeRequest } from '@/utils';

import { SURVEY_PAGE_RATING_QUERY_PARAM, SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM } from './consts';
import { UseFormReturnType } from './types';
import { getSubmitSurveyUrl } from './utils';

export const useForm = (transactionId: string | null): UseFormReturnType => {
  const router = useRouter();
  const lang = useLang();
  const [selectedOption, setSelectedOption] = useState<SurveyOption<number>>();
  const submitSurveyUrl = getSubmitSurveyUrl(transactionId, selectedOption?.value);

  const {
    trigger: submitSurvey,
    isMutating: isLoading,
    error,
  } = useSWRMutation(submitSurveyUrl, (url) => makeRequest(url, { method: 'POST' }));

  const onChange = useCallback((option: SurveyOption<number>) => setSelectedOption(option), []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault();

      if (!transactionId || !selectedOption) {
        return;
      }

      await submitSurvey();

      const { value: rating } = selectedOption;
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set(SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM, transactionId);
      searchParams.set(SURVEY_PAGE_RATING_QUERY_PARAM, rating.toString());

      const url = `${ROUTES.surveyCompleted}?${searchParams.toString()}`;

      router.push(addLocale(url, lang));
    },
    [lang, router, selectedOption, submitSurvey, transactionId],
  );

  return { handleSubmit, onChange, selectedOption, isLoading, error };
};
