import React, { Fragment } from 'react';
import clsx from 'clsx';
import { RadioBaseProps, RadioMode } from './types';
const RadioBase: React.FC<RadioBaseProps> = ({
  mode = RadioMode.Default,
  disabled,
  checked,
  children,
  label,
  ...props
}) => {
  return <label className={clsx('flex items-center', disabled && 'pointer-events-none opacity-60', mode === RadioMode.SurveyQuestion && 'min-h-[2.5rem]', mode === RadioMode.Default && 'text-base')} data-sentry-component="RadioBase" data-sentry-source-file="radioBase.tsx">
      <input type="radio" className="sr-only" disabled={disabled} checked={checked} {...props} />
      {children || <Fragment>
          <span className={clsx('relative mr-4 h-4 w-4 flex-shrink-0 rounded-full border border-yellow-500', 'before:absolute before:left-1/2 before:top-1/2 before:h-full before:w-full before:-translate-x-1/2 before:-translate-y-1/2 before:transform before:rounded-full before:bg-white before:content-[""]', 'after:absolute after:left-1/2 after:top-1/2 after:h-2 after:w-2 after:-translate-x-1/2 after:-translate-y-1/2 after:transform after:rounded-full after:bg-yellow-500 after:content-[""]', checked ? 'after:scale-100' : 'after:scale-0')} />
          {label && <span className="label">{label}</span>}
        </Fragment>}
    </label>;
};
export default RadioBase;